import { EnvironmentType } from '@environments/assets/environment-type.enum';
import { distributionApiTargets } from '@config/apis/distribution/distribution-api-targets';
import { filesApiTargets } from '@config/apis/files/files-api-targets';
import { idApiTargets } from '@config/apis/id/id-api-targets';
import { proxyApiTargets } from '@config/apis/proxy/proxy-api-targets';
import { membershipApiTargets } from '@config/apis/membership/membership-api-targets';
import { AvailableApisType } from '@environments/assets/available-apis.type';
import { EnvironmentInterface } from '@environments/assets/environment.interface';
import { notificationApiTargets } from '@config/apis/notification/notification-api-targets';
import { cmsApiTargets } from "@config/apis/cms/cms-api-targets";

const apis: Record<AvailableApisType, EnvironmentInterface> = {
  cms: cmsApiTargets.staging,
  distribution: distributionApiTargets.staging,
  files: filesApiTargets.staging,
  id: idApiTargets.staging,
  membership: membershipApiTargets.staging,
  notification: notificationApiTargets.staging,
  proxy: proxyApiTargets.staging,
} satisfies Record<AvailableApisType, EnvironmentInterface>;

export const environment = {
  apis,
  oidc: {
    debug: false,
  },
  loadingScreen: {
    debug: false,
  },
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm',
  },
  frontend: {
    baseUrl: 'https://tillitsvalgt.fagforbundet.dev',
    redirectUrl: 'https://tillitsvalgt.fagforbundet.dev/login',
    postLogoutRedirectUri: 'https://fagforbundet.no',
    verifyEmailAddressUrl: 'https://id.fagforbundet.dev/verification/email',
  },
  requestBundle: {
    debug: false,
  },
  router: {
    debug: false,
  },
  pageTitlePrefix: 'Tillitsvalgtnett',
  type: EnvironmentType.STAGING,
  useMocks: false,
};
